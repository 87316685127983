import { ApolloClient } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import cookie from 'js-cookie'
import { storageAvailable } from 'lib/featureDetection'

const GRAPHQL_URI = `${process.env.NEXT_PUBLIC_API_URL}/graphql`

const customFetch = (uri, options) => {
  let token =
    cookie.get(process.env.NEXT_PUBLIC_TOKEN_NAME || 'token') ||
    cookie.get('token')

  if (token) {
    if (!token.startsWith('Bearer')) {
      token = `Bearer ${token}`
    }

    options.headers.authorization = token
  }

  if (storageAvailable('localStorage')) {
    const impersonateUserId = localStorage.getItem('impersonateUserId')
    if (impersonateUserId) {
      options.headers['X-Impersonate-User-Id'] = impersonateUserId
    }
  }

  return fetch(uri, options)
}

const cacheConfig = {
  typePolicies: {
    CurrentUser: {
      fields: {
        conversations: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['search'],
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
        },
        timeSlots: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          merge(existing = [], incoming) {
            return incoming
          },
        },
      },
    },
  },
}

export default function createApolloClient(initialState, ctx) {
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: new BatchHttpLink({
      uri: GRAPHQL_URI, // Server URL (must be absolute)
      credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
      fetch: customFetch,
    }),
    cache: new InMemoryCache(cacheConfig).restore(initialState),
  })
}
